import commerceConfig from '@commerce/config'
import { Cross, Globe } from '@components/icons'
import { Button, Container } from '@components/ui'
import regionSwitcher from '@config/region-switcher.json'
import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import { FC, useState } from 'react'

const COOKIE_NAME = 'COUNTRY_CODE'

const RegionSwitcher: FC = () => {
  const detectedCountryCode = Cookies.get(COOKIE_NAME)

  const {
    config: { context },
  } = commerceConfig

  const router = useRouter()
  const locale = router.locale as string
  const [isClosed, setIsClosed] = useState<Boolean>(false)

  // const currentRegion = context?.[locale!]
  const currentPath = router.asPath === '/' ? null : router.asPath

  const { regions } = regionSwitcher

  if (isClosed) return null

  const detectedRegion = regions.find(
    (region) => region.countryCode === detectedCountryCode
  )

  const currentRegion = regions.find(
    (region) => region.countryCode === context[locale].regionSwitcherCountryCode
  )

  const closeRegionSwitcher = () => {
    const oneMonthFromNow = new Date()
    oneMonthFromNow.setMonth(oneMonthFromNow.getMonth() + 1)

    Cookies.set('REGION_SWITCHER_CLOSED', 'true', {
      expires: oneMonthFromNow,
    })

    setIsClosed(true)
  }

  const isSwitcherClosedCookieSet = Cookies.get('REGION_SWITCHER_CLOSED')

  if (isSwitcherClosedCookieSet === 'true') return null

  if (!detectedRegion) return null

  if (detectedRegion.countryCode === currentRegion?.countryCode) return null
  if (
    detectedRegion.countryCode === 'CA' &&
    currentRegion?.countryCode === 'US'
  )
    return null

  return (
    <div className="p-4 text-sm">
      <Container>
        <div className="relative flex flex-col items-center justify-between md:flex-row">
          <div className="flex justify-between ">
            <div className="hidden md:block">
              <Globe className="fill-ui-darkest-grey" />
            </div>
            <div className="ml-2 flex-col">
              <p className="font-bold">
                Delivery to {detectedRegion.countryName}?
              </p>
              <p>
                You're currently viewing our {currentRegion?.countryName} shop,
                would you prefer to shop on our {detectedRegion.countryName}{' '}
                site?
              </p>
            </div>
          </div>

          <div className="flex w-full flex-col md:w-auto md:flex-row">
            <a href={`${detectedRegion.suggestedUrl}${currentPath || ''}`}>
              <Button
                Component="a"
                className="m-2 w-full md:m-0 md:mr-4 md:w-auto"
              >
                Go To {detectedRegion.countryCode} Site
              </Button>
            </a>

            <Button
              variant="naked"
              className="m-2 w-full bg-white  md:m-0 md:w-auto"
              onClick={closeRegionSwitcher}
            >
              {`Continue Shopping on ${currentRegion?.countryCode} Site`}
            </Button>

            <button
              className="absolute top-0 right-0 ml-4 px-2 pt-1 md:static md:pt-0"
              onClick={closeRegionSwitcher}
            >
              <Cross width={14} height={14} />
            </button>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default RegionSwitcher

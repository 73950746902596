import cn from 'clsx'
import { FC } from 'react'
import { NavColumnList, NavFeaturedCallout } from '../MegaMenuItems'

interface props {
  menuItems: any[]
  handleMegaMenuVisility?: any
  handleHoverOnMegaMenu?: any
}

const MegaMenu: FC<props> = ({
  menuItems,
  handleMegaMenuVisility,
  handleHoverOnMegaMenu,
}) => {
  const handleCloseMegaMenu = () => {
    handleMegaMenuVisility(false)
  }
  const handleMouseOver = () => {
    if (window?.innerWidth >= 1024) {
      handleHoverOnMegaMenu(true)
    }
  }
  const handleMouseOut = () => {
    if (window?.innerWidth >= 1024) {
      handleHoverOnMegaMenu(false)
    }
  }
  const columnsData = menuItems?.filter(
    (item) => item.type === 'mega_nav_column'
  )
  const featuredData = menuItems?.filter(
    (item) => item.type === 'mega_nav_callout'
  )
  if (!columnsData && !featuredData) return <></>
  return (
    <div
      className="bg-ui-light-grey"
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <div className="wrapper mx-auto pt-12 pb-10 ">
        <div className="grid grid-cols-6 gap-6 lg:gap-8">
          {columnsData?.length > 0 &&
            columnsData.map((col) => (
              <div
                key={col.title}
                className={`col-span-6  ${
                  col.links.length > 7 ? 'lg:col-span-2' : 'lg:col-span-1'
                }`}
              >
                <NavColumnList
                  handleCloseMegaMenu={handleCloseMegaMenu}
                  title={col.title}
                  links={col.links}
                />
              </div>
            ))}
          {featuredData?.length > 0 &&
            featuredData.map((featured, index) => (
              <div
                key={featured.title}
                className={cn('col-span-3 mt-4 lg:col-span-1 lg:mt-0', {
                  'lg:col-start-5': index === 0,
                  'lg:col-start-6': index === 0 && featuredData.length === 1,
                })}
              >
                <NavFeaturedCallout
                  title={featured.title}
                  text={featured.description}
                  image={featured.image}
                  link={featured.link}
                  handleCloseMegaMenu={handleCloseMegaMenu}
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default MegaMenu

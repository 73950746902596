import { ChevronDown, ChevronUp } from '@components/icons'
import { useUI } from '@components/ui/context'
import Link from '@components/ui/Link'
import cn from 'clsx'
import { FC, useState } from 'react'

interface props {
  title: string
  links: {
    Label: string
    Link: string
  }[]
  handleCloseMegaMenu: Function
}

const NavColumnList: FC<props> = ({ title, links, handleCloseMegaMenu }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const { closeSidebar } = useUI()

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen)
  }

  const closeMenu = () => {
    handleCloseMegaMenu()
    closeSidebar()
  }
  const isMultiColumn = links.length > 7
  const firstHalf = links.slice(0, 7)
  const secondHalf = links.slice(7)
  return (
    <>
      <div className="body-2-bold mb-5 hidden uppercase text-dark-grey lg:block">
        {title}
      </div>
      <button
        className="body-1 mb-4 flex w-full justify-between text-dark-grey focus:outline-none lg:hidden"
        onClick={toggleDrawer}
      >
        <div>{title}</div>
        <div className="mt-2 lg:hidden">
          {isDrawerOpen ? <ChevronUp /> : <ChevronDown />}
        </div>
      </button>
      <ul
        className={cn('block space-y-4 lg:hidden', {
          'hidden lg:block': !isDrawerOpen,
        })}
      >
        {links.map((link) => (
          <li
            key={link.Label}
            className="body-1 pl-6 text-dark-grey hover:opacity-75 lg:pl-0"
            onClick={closeMenu}
          >
            <Link href={link.Link}>{link.Label}</Link>
          </li>
        ))}
      </ul>
      <div className="hidden lg:block">
        {isMultiColumn ? (
          <div className="lg:grid lg:grid-cols-2 lg:gap-x-4">
            <ul className="space-y-4">
              {firstHalf.map((link) => (
                <li
                  key={link.Label}
                  className="body-1 text-dark-grey hover:opacity-75"
                  onClick={closeMenu}
                >
                  <Link href={link.Link}>{link.Label}</Link>
                </li>
              ))}
            </ul>
            <ul className="space-y-4">
              {secondHalf.map((link) => (
                <li
                  key={link.Label}
                  className="body-1 text-dark-grey hover:opacity-75"
                  onClick={closeMenu}
                >
                  <Link href={link.Link}>{link.Label}</Link>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <ul className="space-y-4">
            {links.map((link) => (
              <li
                key={link.Label}
                className="body-1 text-dark-grey hover:opacity-75"
                onClick={closeMenu}
              >
                <Link href={link.Link}>{link.Label}</Link>
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  )
}

export default NavColumnList

import { Menu } from '@components/icons'
import { useUI } from '@components/ui/context'
import { FC, useRef, useState } from 'react'

interface props {
  iconsColour: string
}

const MobileNav: FC<props> = ({ iconsColour }) => {
  const megaNavRef = useRef<HTMLDivElement>(null)
  const [openNav, setOpenNav] = useState(false)

  const {
    openSidebar,
    closeSidebar,
    openMobileNav,
    closeMobileNav,
    openSearch,
    closeSearch,
  } = useUI()

  // const handleOpenNav = () => {
  //   const sidebar = megaNavRef.current
  //   setOpenNav(true)
  // }

  // const handleCloseNav = () => {
  //   const sidebar = megaNavRef.current
  //   enableBodyScroll(sidebar)
  //   clearAllBodyScrollLocks()
  //   setOpenNav(false)
  // }

  // useEffect(() => {
  //   if (openNav) {
  //     openSidebar()
  //   } else {
  //     closeSidebar()
  //   }
  // }, [openNav])

  return (
    <>
      <div className="">
        <Menu fill={iconsColour} />
      </div>
    </>
  )
}

export default MobileNav
